import React, {useState, useEffect, useCallback} from 'react';
import '../../assets/styles/simplbar.css';
import {uiSelector} from 'state/ui';
import SimpleBar from 'simplebar-react';
import {makeStyles} from '@material-ui/core/styles';
import { GoogleMap, useJsApiLoader,InfoWindow,Marker } from '@react-google-maps/api';
import MapIcon from '../../assets/images/mapicon1.png';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    isTablet
} from "react-device-detect";
import "../../assets/styles/googleMaps.css";

const useStyles = makeStyles((theme) => ({
    root: {},
    divStyle:{
        fontSize: '12px !important',
        padding: '10px 19px 3px 20px',
        width: 280,
        height: 125,
        "@media only screen and (max-width : 480px)": {
            width: '100%',
            height: '100%'
        },
        '& h4':{
          fontWeight: '500',
            font: '12px Gotham, sans-serif'
        },
        '& ul':{
            padding: '0px 20px 0 13px',
            listStyle: 'disc',
            font: '12px Gotham, sans-serif'
        },
        '& li':{
            paddingLeft: '20px',
            listStyle: 'disc',
            font: '12px Gotham, sans-serif',
        },
        '& .openMaps a':{
            color: '#e9098c',
            fontWeight: '300',
            margin: '6px 0 2px 0',
            display: 'block',
            font: '12px Gotham, sans-serif',
        },

        '& img': {
            background: '#fafafa',
            padding: '5px',
            maxWidth: '35px'
        },
        backgroundColor: '#fff',
    },
    Mapicon:{
        display: 'block'
    },

}));

const containerStyle = {
    width: '100%',
    height: '100%',
    borderRadius: 0
};

export default function StoresLocation({ location, locations, language }) {

    // Handle loading more articles
    useEffect(() => {

    }, [])

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyBUu8jX1Csbyy6-Pb5zxt4PBH1hujMADpo"
    })

    const [showInfo, setShowInfo] = React.useState({
        display: false,
        position: {}
    })

    const classes = useStyles();

    // CENTER CITY GREECE
    const centers = [{
        lat: 37.9779436,
        lng: 23.734241
    }];

    const infoWindow = React.useCallback(function callback( positionS, index, city ){
        setShowInfo({
            display: true,
            index: index,
            position: positionS,
            city: city
        });
    }, []);

    const infoWindowExit = React.useCallback(function callback( positionS, index, city ){
        setShowInfo({
            display: true,
            index: index,
            position: positionS,
            city: city
        });
    }, []);

    const exampleMapStyles = [
        {
            featureType: "poi",
            elementType: "geometry",
            stylers: [
                {
                    color: "#eeeeee",
                },
            ],
        },
        {
            featureType: "poi",
            elementType: "labels.text",
            stylers: [
                {
                    visibility: "off",
                },
            ],
        },
        {
            featureType: "water",
            elementType: "labels.text.fill",
            stylers: [
                {
                    color: "#9e9e9e",
                },
            ],
        },
    ];

    let cityRender = [];

    return (
        <>
        <section className="stores">

            <div className="container">

                {
                    (location.pathname === '/' || location.pathname === '/about/') &&
                    <div className="row">

                        <div className={(location.pathname==='/about/') ? `col section-title text-start` : `col section-title text-end`}>

                            <h2>{( language !== 'en') ? 'καταστήματα': 'stores' }</h2>

                        </div>

                    </div>
                }

                <div className="row">

                    <div className="col-lg-3 col-sm-12">

                        <div className="maplist">

                            <h3>{( language !== 'en') ? 'ΒΡΕΙΤΕ ΕΝΑ ΚΑΤΑΣΤΗΜΑ': 'FIND A STORE' } </h3>

                            <SimpleBar className="storelist">

                                {locations.nodes.map((location, index) => {

                                    const getLatLong = [{
                                        lat: location?.locationInformation?.latitude,
                                        lng: location?.locationInformation?.longitude
                                    }];

                                    if( language !== 'en' && location.locationInformation){

                                        if( !cityRender.includes(location.locationInformation.city) ){
                                            return (
                                                <div className="store-area">
                                                    <strong className={(showInfo.city === location?.locationInformation?.city) ? 'active' : ''} style={{ cursor: 'pointer'}} onClick={()=>{
                                                        infoWindow(getLatLong, index, location?.locationInformation?.city)
                                                    }}>{ (language !== 'en') ? location?.locationInformation?.city : location['translations'][0]?.locationInformation?.city }</strong>
                                                    <ul style={{ cursor: 'pointer'}}>
                                                        {
                                                            //  Loop through again to get cities
                                                            locations.nodes.map((loc, i) => {

                                                                cityRender.push(location?.locationInformation?.city);

                                                                if(loc?.locationInformation?.city.trim() === location?.locationInformation?.city.trim() ){

                                                                    const getLatLongInner = [{
                                                                        lat: loc?.locationInformation?.latitude,
                                                                        lng: loc?.locationInformation?.longitude
                                                                    }];

                                                                    return(
                                                                        <li className={(showInfo.index === i ) ? 'active' : ''}>
                                                                            <span onClick={() => {
                                                                                infoWindow(getLatLongInner, i, loc?.locationInformation?.city)
                                                                            }}>
                                                                                 {(language !== 'en') ? loc?.locationInformation?.address : loc['translations'][0]?.locationInformation?.address}<br/>
                                                                                {(language !== 'en') ? 'Tηλ.' : 'Tel.'}
                                                                                    <em> {(language !== 'en') ?
                                                                                        <a href={`tel:${loc?.locationInformation?.telephone}`}>{loc?.locationInformation?.telephone}</a> :
                                                                                        <a href={`tel:${loc['translations'][0]?.locationInformation?.telephone}`}>{loc?.locationInformation?.telephone}</a>}</em>
                                                                                <a className='linkGatsby'
                                                                                   href={loc?.locationInformation?.directions}
                                                                                   target={'_blank'}></a>
                                                                            </span>

                                                                        </li>
                                                                    )
                                                                }

                                                            })
                                                        }

                                                    </ul>

                                                </div>
                                            );
                                        }

                                    }else if(location.translations[0]){

                                        if( !cityRender.includes(location.locationInformation.city) ){
                                            return (
                                                <div className="store-area">
                                                    <strong className={(showInfo.city === location?.locationInformation?.city) ? 'active' : ''} style={{ cursor: 'pointer'}} onClick={()=>{
                                                        infoWindow(getLatLong, index, location?.locationInformation?.city)
                                                    }}>{ (language !== 'en') ? location?.locationInformation?.city : location['translations'][0]?.locationInformation?.city }</strong>
                                                    <ul style={{ cursor: 'pointer'}}>
                                                        {
                                                            //  Loop through again to get cities
                                                            locations.nodes.map((loc, i) => {

                                                                cityRender.push(location?.locationInformation?.city);

                                                                if(loc?.locationInformation?.city.trim() === location?.locationInformation?.city.trim() ){

                                                                    const getLatLongInner = [{
                                                                        lat: loc?.locationInformation?.latitude,
                                                                        lng: loc?.locationInformation?.longitude
                                                                    }];

                                                                    return(
                                                                        <li>
                                                                            <span onClick={() => {
                                                                                infoWindow(getLatLongInner, i, loc?.locationInformation?.city)
                                                                            }}>
                                                                                 {(language !== 'en') ? loc?.locationInformation?.address : loc['translations'][0]?.locationInformation?.address}<br/>
                                                                            </span>
                                                                            {(language !== 'en') ? 'Tηλ.' : 'Tel.'}
                                                                            <em> {(language !== 'en') ?
                                                                                <a href={`tel:${loc?.locationInformation?.telephone}`}>{loc?.locationInformation?.telephone}</a> :
                                                                                <a href={`tel:${loc['translations'][0]?.locationInformation?.telephone}`}>{loc?.locationInformation?.telephone}</a>}</em>
                                                                            <a className='linkGatsby'
                                                                               href={loc?.locationInformation?.directions}
                                                                               target={'_blank'}></a>
                                                                        </li>
                                                                    )
                                                                }

                                                            })
                                                        }

                                                    </ul>

                                                </div>
                                            );
                                        }

                                    }

                                })}

                            </SimpleBar>

                            <div className="bottomLocations"></div>

                        </div>

                    </div>

                    <div className="col-lg-9 col-sm-12">

                        <div className="map">

                            {
                                (isLoaded)&&
                                <GoogleMap
                                    options={{ styles: [{ stylers: [{ 'saturation': -100 }] }] }}
                                    mapContainerStyle={containerStyle}
                                    center={centers[0]}
                                    zoom={11}
                                >

                                    {
                                        locations.nodes.map((location, index) => {

                                            const getLatLong = [{
                                                lat: location?.locationInformation?.latitude,
                                                lng: location?.locationInformation?.longitude
                                            }];

                                            const infoWindow =
                                                    (showInfo.display && showInfo.index === index ) &&
                                                    <InfoWindow
                                                        onCloseClick={()=>{ setShowInfo({ display: false, position: null})}}
                                                        className={`${classes.infoWindow} infoWindow`}
                                                        arrowPosition={30}
                                                    >
                                                        <div className={ classes.divStyle}>
                                                            <h4>{(language !== 'en') ? location?.locationInformation?.city : location['translations'][0]?.locationInformation?.city}</h4>
                                                            <ul>
                                                                <li>
                                                                            <span>
                                                                                {(language !== 'en') ? location?.locationInformation?.address : location['translations'][0]?.locationInformation?.address } <br/>
                                                                            </span>
                                                                    { (language !== 'en') ? 'Tηλ.' : 'Tel.'} { (language !== 'en') ? <a style={{ textDecoration: 'underline', outline: 'none'}} href={`tel:${location?.locationInformation?.telephone}`}>{location?.locationInformation?.telephone}</a> : <a href={`tel:${location['translations'][0]?.locationInformation?.telephone}`}>{location?.locationInformation?.telephone}</a> }

                                                                </li>
                                                            </ul>
                                                            <div className="d-flex justify-content-between align-items-center openMaps">
                                                                <div className="p-2">
                                                                    <a href={location?.locationInformation?.directions} target={'_blank'}>
                                                                        {(language !== 'en') ? 'Άνοιγμα στο Google Maps' : 'Open in Google Maps'}
                                                                    </a>
                                                                </div>
                                                                <a href={location?.locationInformation?.directions} target={'_blank'}>
                                                                    <div className={`${classes.Mapicon}`}><img src={MapIcon} /></div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </InfoWindow>;

                                            return(

                                                    ( isTablet || isMobile )
                                                    ?
                                                    <Marker
                                                        icon={{
                                                            url:'/map-pin-normal.png',
                                                            scaledSize: new window.google.maps.Size(30, 30)
                                                        }}
                                                        position={getLatLong[0]}
                                                        // onMouseOver={()=>{infoWindow(getLatLong, index)}}
                                                        onMouseDown={()=>{infoWindowExit(getLatLong, index)}}
                                                    >
                                                        {infoWindow}
                                                    </Marker>
                                                    :
                                                    <Marker
                                                        icon={{
                                                            url:'/map-pin-normal.png',
                                                            scaledSize: new window.google.maps.Size(30, 30)
                                                        }}
                                                        position={getLatLong[0]}
                                                        // onMouseOver={()=>{infoWindow(getLatLong, index)}}
                                                        onMouseOver={()=>{infoWindowExit(getLatLong, index)}}
                                                    >
                                                        {infoWindow}
                                                    </Marker>

                                            );

                                        })
                                    }

                                </GoogleMap>
                            }

                        </div>

                    </div>

                </div>

            </div>

        </section>
        </>
    );
};
